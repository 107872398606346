import { type NavItem } from '@/components/nav/nav-types';
import { Film, Heart, Home, Navigation, Users } from 'lucide-react';
import { FaRunning } from 'react-icons/fa';

export const navItems: NavItem[] = [
  {
    label: 'Home',
    link: '/',
    icon: <Home className="mr-2 h-6 w-6" />,
    segment: 'home',
  },
  {
    label: 'Donate',
    link: '/donate',
    icon: <Heart className="mr-2 h-6 w-6" />,
    segment: 'donate',
  },
  {
    label: 'Track',
    link: '/track',
    icon: <Navigation className="mr-2 h-6 w-6" />,
    segment: 'track',
  },
  {
    label: 'Support Crew',
    link: '/support-crew',
    icon: <Users className="mr-2 h-6 w-6" />,
    segment: 'support-crew',
  },
  {
    label: 'Media',
    link: '/media',
    icon: <Film className="mr-2 h-6 w-6" />,
    segment: 'media',
  },
  {
    label: 'Contact Us',
    link: '/contact',
    icon: <Users className="mr-2 h-6 w-6" />,
    segment: 'contact',
  },
  {
    label: 'Run with Paul',
    link: '/run-with-paul',
    icon: <FaRunning className="mr-2 h-6 w-6" />,
    segment: 'run-with-paul',
  },
];

export type NavSegment =
  | 'home'
  | 'donate'
  | 'track'
  | 'support-crew'
  | 'contact'
  | 'run-with-paul';
